<template>
  <div class="bgcard">
    <p>{{ $t("lang.Addpackage") }}</p>
    
    <div class="form_tops">
      <el-form
        :label-position="'right'"
        label-width="190px"
        :model="mealRecord"
        :rules="rules"
        ref="mealRecord"
      >
        <div class="basic">
          <div>{{ $t("lang.EssentialInformation") }}</div>
          <span></span>
        </div>
        <div class="formcenter">
          <div class="forlmleft">
            <el-form-item :label="$t('lang.Packagename')" prop="name">
              <el-input
                :maxlength="100"
                :disabled="allDisabled"
                v-model="mealRecord.name"
              ></el-input>
            </el-form-item>
          </div>
          <div class="forlmleft">
            <el-form-item :label="$t('lang.Months')" prop="unit">
              <el-input-number
                style="width: 100%"
                :disabled="allDisabled"
                v-model="mealRecord.unit"
                :step="1"
                :min="1"
                :max="60"
                step-strictly
              ></el-input-number>
            </el-form-item>
          </div>
        </div>
        <div class="formcenter">
          <div class="forlmleft">
            <el-form-item :label="$t('lang.starttime')" prop="startTime">
              <el-date-picker
                style="width: 100%"
                v-model="alldates"
                :disabled="allDisabled"
                type="datetimerange"
                :start-placeholder="$t('lang.Startdate')"
                :end-placeholder="$t('lang.Enddate')"
                @change="datepickerchange"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="forlmleft">
            <el-form-item :label="$t('lang.Costunitprice')" prop="unitPrice">
              <el-input
                v-model="mealRecord.unitPrice"
                type="number"
                :min="1"
                :maxlength="5"
                :disabled="allDisabled"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="formcenter">
          <div class="forlmleft">
            <el-form-item :label="$t('lang.Costunit')" prop="chargingUnit">
              <el-input
                v-model="mealRecord.chargingUnit"
                :disabled="allDisabled"
                maxlength="5"
              ></el-input>
            </el-form-item>
          </div>
          <div class="forlmleft">
            <el-form-item :label="$t('lang.Discountfee')" prop="discountPrice">
              <el-input
                v-model="mealRecord.discountPrice"
                :disabled="allDisabled"
                maxlength="5"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="formcenter">
          <div class="forlmleft">
            <el-form-item :label="$t('lang.Flowlimit')" prop="flowQuota">
              <el-input
                type="number"
                :min="1"
                :max="50000"
                v-model="mealRecord.flowQuota"
                :disabled="allDisabled"
                ><template slot="append">GB</template></el-input
              >
            </el-form-item>
          </div>
          <div class="forlmleft">
            <el-form-item :label="$t('lang.storagequota')" prop="storageQuota">
              <el-input
                type="number"
                :min="1"
                v-model="mealRecord.storageQuota"
                :disabled="allDisabled"
                :max="50000"
                ><template slot="append">GB</template></el-input
              >
            </el-form-item>
          </div>
        </div>

        <div
          class="formcenter"
          style="align-items: inherit; justify-content: flex-start"
        >
          <div class="forlmleft">
            <el-form-item :label="$t('lang.sort')">
              <el-input-number
                :disabled="allDisabled"
                style="width: 100%"
                v-model="mealRecord.orderBy"
                :step="1"
                :min="0"
                :max="6"
                step-strictly
              ></el-input-number>
            </el-form-item>
          </div>
          <div class="forlmleft">
            <el-form-item label="LOGO：">
              <!-- v-if="allDisabled ? fileList.length > 0 : false" -->
              <el-upload
                :action="uploadUrl"
                :headers="headers"
                :limit="1"
                :class="{ disabled: uploadDisabled }"
                list-type="picture-card"
                :on-success="handleAvatarSuccess"
                :file-list="fileList"
              >
                <i slot="default" class="el-icon-plus"></i>
                <div
                  slot="file"
                  slot-scope="{ file }"
                  style="width: 100%; height: 100%"
                >
                  <img
                    style="width: 100%; height: 100%"
                    class="el-upload-list__item-thumbnail"
                    :src="file.url"
                    alt=""
                  />
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                      v-if="!allDisabled"
                      class="el-upload-list__item-delete"
                      @click="handleRemove(file)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
              <!-- <span v-else style="color: #b2b2b2"> /</span> -->
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>
          </div>
        </div>
        <div class="basic">
          <div>{{ $t("lang.Excessstrategy") }}</div>
          <span></span>
        </div>
        <div class="formcenter">
          <div class="forlmleft">
            <el-form-item
              :label="$t('lang.Excesstrafficstrategy')"
              prop="flowStrategy"
              label-width="230px"
            >
              <el-select
                v-model="mealRecord.flowStrategy"
                :disabled="allDisabled"
                :placeholder="$t('lang.Please')"
                style="width: 100%"
              >
                <el-option :label="$t('lang.OutofService')" :value="0">
                </el-option>
                <el-option :label="$t('lang.Additionalbilling')" :value="1">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="forlmleft">
            <el-form-item :label="$t('lang.danjiafujia')" label-width="230px">
              <el-input
                :disabled="allDisabled ? true : mealRecord.flowStrategy != 1"
                v-model="mealRecord.flowAdditionalUnit"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="formcenter">
          <div class="forlmleft">
            <el-form-item
              :label="$t('lang.cucunxiane')"
              prop="storageStrategy"
              label-width="230px"
            >
              <el-select
                :disabled="allDisabled"
                v-model="mealRecord.storageStrategy"
                :placeholder="$t('lang.Please')"
                style="width: 100%"
              >
                <el-option :label="$t('lang.OutofService')" :value="0">
                </el-option>
                <el-option :label="$t('lang.Additionalbilling')" :value="1">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="forlmleft">
            <el-form-item :label="$t('lang.fujiacucen')" label-width="230px">
              <el-input
                :disabled="mealRecord.storageStrategy != 1 && allDisabled"
                v-model="mealRecord.storageAdditionalUnit"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="basic">
          <div>{{ $t("lang.Promptinformation") }}</div>
          <span></span>
        </div>
        <div
          class="formcenter"
          style="
            display: flex;
            justify-content: flex-start;
            align-items: inherit;
          "
        >
          <div class="forlmleft" style="width: 100%">
            <el-form-item :label="$t('lang.Purchasetips')" prop="tips">
              <el-input
                type="textarea"
                v-model="mealRecord.tips"
                :disabled="allDisabled"
                :maxlength="235"
                :autosize="{ minRows: 5, maxRows: 5 }"
              ></el-input>
            </el-form-item>
          </div>
          <!-- <div class="forlmleft">
            <el-form-item :label="$t('lang.Serviceinstructions')" prop="notice">
              <el-input
                type="textarea"
                v-model="mealRecord.notice"
                :disabled="allDisabled"
              ></el-input>
            </el-form-item>
          </div> -->
        </div>
        <div
          class="formcenter"
          style="
            display: flex;
            justify-content: flex-start;
            align-items: inherit;
          "
        >
          <!-- <div class="forlmleft">
            <el-form-item :label="$t('lang.Purchasetips')" prop="tips">
              <el-input
                type="textarea"
                v-model="mealRecord.tips"
                :disabled="allDisabled"
              ></el-input>
            </el-form-item>
          </div> -->
          <div class="forlmleft" style="width: 100%">
            <el-form-item :label="$t('lang.Serviceinstructions')" prop="notice">
              <el-input
                type="textarea"
                v-model="mealRecord.notice"
                :disabled="allDisabled"
                :maxlength="235"
                :autosize="{ minRows: 5, maxRows: 5 }"
              ></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <div class="btnstyle">
      <button @click="preservation('mealRecord')" v-if="!allDisabled">
        {{ $t("lang.preservation") }}
      </button>
      <button @click="returns">{{ $t("lang.return") }}</button>
    </div>
  </div>
</template>

<script>
import { upload } from "@/utils/request";
import * as contract from "@/api/contract.js";
export default {
  data() {
    var accessPort = (rule, value, callback) => {
      if (value == "") {
        callback(this.$t("lang.accessPort"));
      } else {
        callback();
      }
    };
    var discountPrice = (rule, value, callback) => {
      if (value >= Number(this.mealRecord.unitPrice * this.mealRecord.unit)) {
        callback(this.$t("lang.youhuijiafe"));
      } else {
        callback();
      }
    };
    var unitPricePort = (rule, value, callback) => {
      if (value <= 0) {
        callback(this.$t("lang.maxprices"));
      } else if (value >= 9999) {
        callback(this.$t("lang.maxprceaaa"));
      } else {
        callback();
      }
    };
    var QuotaPort = (rule, value, callback) => {
      if (value <= 0) {
        callback(this.$t("lang.xianerices"));
        // callback(this.$t("lang.Pleaseentertheport"));
      } else if (value > 50000) {
        callback(this.$t("lang.maxnumbers"));
      } else {
        callback();
      }
    };
    return {
      istypes:true,
      rules: {
        name: [
          {
            required: true,
            message: this.$t("lang.addnames"),
            trigger: "blur",
          },
        ],
        unit: [
          {
            required: true,
            message: this.$t("lang.datamesg"),
            trigger: "blur",
          },
        ],
        startTime: [
          {
            required: true,
            message: this.$t("lang.alldatamesg"),
            trigger: "change",
          },
        ],
        discountPrice: [
          {
            required: true,
            message: this.$t("lang.zekomesgs"),
            trigger: "blur",
          },
          { required: true, validator: discountPrice, trigger: "blur" },
        ],
        chargingUnit: [
          {
            required: true,
            message: this.$t("lang.feiyongmesg"),
            trigger: "blur",
          },
          { required: true, validator: accessPort, trigger: "blur" },
        ],
        unitPrice: [
          {
            required: true,
            message: this.$t("lang.danjialsit"),
            trigger: "blur",
          },
          { required: true, validator: unitPricePort, trigger: "blur" },
        ],
        flowQuota: [
          {
            required: true,
            message: this.$t("lang.liuxianes"),
            trigger: "blur",
          },
          { required: true, validator: QuotaPort, trigger: "blur" },
        ],
        storageQuota: [
          {
            required: true,
            message: this.$t("lang.cunxianes"),
            trigger: "blur",
          },
          { required: true, validator: QuotaPort, trigger: "blur" },
        ],
        flowStrategy: [
          {
            required: true,
            message: this.$t("lang.chaoecel"),
            trigger: "change",
          },
        ],
        storageStrategy: [
          {
            required: true,
            message: this.$t("lang.chaoecel"),
            trigger: "change",
          },
        ],
        tips: [
          {
            required: true,
            message: this.$t("lang.Pleaseinputthecontent"),
            trigger: "blur",
          },
        ],
        notice: [
          {
            required: true,
            message: this.$t("lang.Pleaseinputthecontent"),
            trigger: "blur",
          },
        ],
      },
      alldates: [],
      uploadUrl: upload,
      headers: {
        Authorization: JSON.parse(sessionStorage.getItem("token")),
      },
      fileList: [],
      mealRecord: {
        name: "", //套餐名称
        flowQuota: "", //流量限额GB
        flowStrategy: "", //流量超额策略选项：0停止服务/1附加计费
        flowAdditionalUnit: "", //流量超额策略选项：0停止服务/1附加计费
        allPrice: "", //合计费用：单价*月数，精确到2为小数
        chargingUnit: "", //费用单位：管理员定义的任意货币符号（英文符号）；
        discountPrice: "", //折扣费用：由管理员输入，精确到2为小数 （用户支付的金额）
        endTime: "", //起始时间和截至时间，精确到分钟；
        startTime: "", //起始时间和截至时间，精确到分钟；
        status: true, //状态选项：启用/禁用
        storageQuota: "", //存储限额GB
        storageStrategy: "", //存储限额超额策略选项，0停止服务/1附加计费
        storageAdditionalUnit: "", //附件存储单价，以GB为单位
        timeUnit: "", //时间单位：自然月；
        tips: "", //购买提示：在用户尝试购买时提示用户确认的信息。
        unit: "", //月数：1—60，即最少1个月即30天计算
        unitPrice: "", //单价：精确到两位小数
        notice: "", //服务须知，购买前需要先同意该须知才能点击确认购买
        orderBy: "", //排序
        logo: "", //logo
        id: "",
      },
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      page: 1,
      id: "",
      uploadDisabled: false,
      allDisabled: false,
    };
  },
  created() {
    if (this.$route.query.deside) {
      this.allDisabled = true;
    }
    
    if (this.$route.query.page) {
      this.mealRecord = JSON.parse(this.$route.query.data);
      this.alldates.push(this.mealRecord.startTime);
      this.alldates.push(this.mealRecord.endTime);
      if (this.mealRecord.logo) {
        this.fileList.push({ url: this.mealRecord.logo });
      }

      if (this.fileList.length > 0) {
        this.uploadDisabled = true;
      }
      // debugger
      this.page = this.$route.query.page;
    }
  },
  methods: {
   
   
    // 时间选择
    datepickerchange(e) {
      console.log(this.alldates);
      let arrs = [];
      e.forEach((element) => {
        arrs.push(this.datestrins(element));
      });
      this.mealRecord.startTime = arrs[0];
      this.mealRecord.endTime = arrs[1];
    },
    // 时间转换
    datestrins(obg) {
      var date = new Date(obg);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      let time = y + "-" + m + "-" + d + " " + h + ":" + minute + ":00";
      return time;
    },
    // 保存
    preservation(formName) {
      console.log(this.mealRecord);

      if (this.mealRecord.logo == "") {
        this.$message.error(this.$t("lang.addimage"));
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          contract.mealRecordaddOredit(this.mealRecord).then((res) => {
            if ((res.data = "SUCCESS")) {
              this.$message.success(this.$t("lang.addsuccess"));
              this.$router.push({
                path: "packagelist?page=" + this.page,
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    // 返回
    returns() {
      this.$router.push({
        path: "packagelist?page=" + this.page,
      });
    },
    //上传成功
    handleAvatarSuccess(res, file) {
      // debugger
      this.mealRecord.logo = res.data.path;
      this.uploadDisabled = true;

      // this.imageUrl = URL.createObjectURL(file.raw);
      // this.imgurl = file.response.data;
    },
    // 删除图片
    handleRemove(file) {
      this.fileList = [];
      this.mealRecord.logo = "";
      this.uploadDisabled = false;
    },
    // 预览图片
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
  },
};
</script>

<style>
.disabled .el-upload--picture-card {
  display: none !important;
}
</style>
<style lang="less" scoped>
/deep/.el-form-item__label {
  color: #dbdbdb !important;
}
/deep/.el-input__inner {
  background: none !important;
  color: #b2b2b2 !important;
}
/deep/.el-input-number__decrease {
  background: none !important;
}
/deep/.el-input-number__increase {
  background: none !important;
}
/deep/.el-input-number__increase:hover {
  color: #b2b2b2 !important;
}
/deep/.el-input___inner {
  background: none !important;
}
/deep/.el-date-editor .el-range-input {
  background: none !important;
}
/deep/.el-upload--picture-card {
  background: none !important;
}
/deep/.el-textarea__inner {
  background: none !important;
  color: #b2b2b2 !important;
}
/deep/.el-input__inner:focus {
  border-color: #b2b2b2 !important;
}
/deep/.el-input-number__decrease:hover {
  color: #b2b2b2 !important;
}
/deep/.el-upload--picture-card:hover {
  border-color: #b2b2b2 !important;
  color: #b2b2b2 !important;
}
/deep/.el-upload:focus {
  border-color: #b2b2b2 !important;
  color: #b2b2b2 !important;
}
/deep/.el-textarea__inner:focus {
  border-color: #b2b2b2 !important;
}
/deep/.el-select .el-input .el-input__inner {
  color: #b2b2b2 !important;
}
/deep/.el-range-input {
  color: #b2b2b2 !important;
}
/deep/.el-input-group__append {
  background: none;
  color: #b2b2b2 !important;
}
.bgcard {
  border: 0px;
  box-sizing: border-box;
  padding: 0.3rem;
  border-radius: 0.02rem;
  background: #222628;

  p {
    color: #dbdbdb;
    font-size: 0.18rem;
    font-weight: bold;
  }

  .information {
    margin-top: 0.37rem;
  }
}
.basic {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0.2rem 0;

  div {
    color: #dbdbdb;
    font-size: 0.16rem;
  }

  span {
    margin-left: 0.14rem;
    width: 80%;
    border-top: 0.01rem solid #797979;
  }
}
.form_tops {
  .formcenter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    .forlmleft {
      width: 45%;
      padding: 0 0.1rem;
      margin-right: 0.5rem;
    }
  }
}
.btnstyle {
  margin-top: 0.3rem;

  button {
    outline-style: none;
    border: none;

    &:first-of-type {
      width: 1.31rem;
      height: 0.54rem;
      background: #307b37;
      border-radius: 0.04rem;
      font-size: 0.18rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      margin-right: 0.16rem;
    }

    &:last-of-type {
      width: 1.31rem;
      height: 0.54rem;
      background: #222628;
      border: 0.01rem solid #307b37;
      border-radius: 0.04rem;
      font-size: 0.18rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #307b37;
    }
  }
}
</style>